$(document).ready(function(){


    //sticky header
    $(window).scroll(function() {
        if ($(window).scrollTop() > 1){

            $('.top-header').addClass("sticky");
        }
        else{

            $('.top-header').removeClass("sticky");
        }
    });


    // header mobile menu toggle

    $(".menu").click(function(){
        $(".dropdown-nav").toggleClass("dropdown-nav-active");
        $(this).toggleClass("clicked");

        if($(".dropdown-nav").hasClass("dropdown-nav-active")){

            $("body, html").css({"overflow-y":"hidden"})

        }
        else{

            $("body, html").css({"overflow-y":""})

        }

    });


    // function to set header list height

    function setHeight (){
    var fHeight = $(".navigation li:first-of-type").height();
    $(".navigation li:first-of-type").siblings("li").not(".menu").height(fHeight);
    }


    if ($(window).width() >767) {
        setHeight();

    }

    $(window).on("resize",function() {
        if ($(window).width() >767) {
            setHeight();
        }

    });


    //function to set Homepage size + dropdown menu attributes
    function setHome(){
    var BHeight = $('body').height();
    var THeight = $('.top-header').outerHeight();
    var FHeight = $('footer').outerHeight();
    var hHeight = BHeight - THeight - FHeight ;
    $('.home').outerHeight(hHeight);
        $(".dropdown-nav").css("top",THeight+ 'px');
        $(".content").css("padding-top",THeight+ 'px');
    }


    //function to set international racing sizes

    function intRHeight(){
        $('.top-right').outerHeight($('.top-left').outerHeight());
    }


    //function to set international racing sizes

    function portfolioHeight(){
        $('.sport-text').outerHeight($('.sport-img').outerHeight());
        $('.text-container').outerHeight($('.sport-text').height());

    }


    //Load all scripts after elements are loaded + windows resize

    $(window).on("load",function() {
        setHome();
        intRHeight();
        portfolioHeight();


    });

    $(window).on("resize",function() {
        setHome();
        intRHeight();
        portfolioHeight();


    });



    // Get current url
    // Select an a element that has the matching href and apply a class of 'current-page' to highlight the current page.
    var href = document.location.href;
    var lastPathSegment = href.substr(href.lastIndexOf('/') + 1);
    // alert(lastPathSegment);
    $('.links a').each(function(index) {
        var current = this.href;
        currentlastSegment = current.substr(current.lastIndexOf('/') + 1);

        if (currentlastSegment == lastPathSegment)
            if (lastPathSegment == "" && lastPathSegment != undefined) {
                $(this).addClass("current-page");
            } else {
        $(this).addClass("current-page");
        $(this).closest("li.sub-menu").find("a:first").addClass("current-page");
    }
    });




    //Navigation sub menu query

    $("ul li ").click(function(){
        $(this).children("ul").toggleClass("active-sub");

    });



    // About Moneta Paragraph Control

    $number_paragraphs = $('.about-text').find('.scroll-text').length;
    $atext_pos = 0;


    $(".more-text").click(function(){
        if($atext_pos<$number_paragraphs-1){

        $('.about-text').find('.scroll-text').eq($atext_pos).slideUp(1000);
            $('.about-text').height($('.scroll-text').eq($atext_pos).outerHeight());
            $atext_pos++;

        }
    });


    // Contact form link

    $(".about-contact").click(function(){
        window.location = '/contact';


    });


    //portfolio dropdown

    $(".portfolio .panel-title a ").click(function(){
        $(".portfolio .panel-title a ").not($(this)).find("span").hide();
        $(this).find("span").toggle();
        $(this).parents(".panel-default").siblings().toggle(800);

    });


    //affiliates carousel
    $('.brands').slick({
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows:false
    });


    //Irish Horse Racing Desktop Transiction

    $(function() {
        $.fx.interval = 0;
        (function cycleBgImage(elem, bgimg) {
            // set, reset, delay to `1000` after background image reset
            elem.css("backgroundImage", bgimg)
                // fade in background image
                .fadeTo(5000, 1, "linear", function() {
                    // fade in background image
                    $(this).delay(5000, "fx").fadeTo(1000, 0, "linear", function() {
                        // split background image string at comma , creating array
                        var img = $(this).css("backgroundImage").split(","),
                        // concat first background image to `img` array,
                        // remove first background image from `img` array
                            bgimg = img.concat(img[0]).splice(1).join(",");
                        // recursively call `cycleBgImage`
                        cycleBgImage(elem, bgimg);
                    });
                });
        }($(".sliding-bg")));
    });


    $(".slider-nav > .prev").click(function(){

        var img = $(".sliding-bg").css("backgroundImage").split(","),
            bgimg = img.concat(img[1]).splice(2).join(",");
        $(".sliding-bg").css("backgroundImage", bgimg).fadeTo(5000, 1);
    });


    $(".slider-nav > .next").click(function(){

        var img = $(".sliding-bg").css("backgroundImage").split(","),
            bgimg = img.concat(img[0]).splice(1).join(",");
        $(".sliding-bg").css("backgroundImage", bgimg).fadeTo(5000, 1);

    });


    //hover dropdown

    $(".top-header > .navigation > li").hover(function(){

        $(".dropdown-nav-lg").addClass("dropdown-nav-lg-active");
    });



    /**** Sport Portfolio   ***/



    $( ".text-container" ).each(function() {

        $sections = $(this).find("section").length;
        if($sections>1){
            $(this).parent( ".sport-text" ).append( "<img src='/images/dropdown-2.png'>" );

        }

    });


    /**** Website Scroll Bar Functions ********/


    $(window).on("resize",function() {
        if ($(window).width() <768) {

            $(".irish-racing").mCustomScrollbar({
                setHeight: "400px",
                theme:"dark"
            });



            $(".horse-racing").mCustomScrollbar({
                setWidth: "100%",
                setHeight: "440px",
                theme:"dark"
            });


            $(".about-text").mCustomScrollbar({
                setWidth: "100%",
                setHeight: "260px",
                theme:"dark"
            });

        }
        else{
            $(".irish-racing").mCustomScrollbar("destroy");
            $(".about-text").mCustomScrollbar("destroy");


            $(".horse-racing").mCustomScrollbar({
                setHeight: "490px",
                theme:"dark"
            });
        }

    });

    if ($(window).width() <768) {

        $(".irish-racing").mCustomScrollbar({
            setHeight: "400px",
            theme:"dark"
        });



        $(".horse-racing").mCustomScrollbar({
            setWidth: "100%",
            setHeight: "440px",
            theme:"dark"
        });

        $(".about-text").mCustomScrollbar({
            setWidth: "100%",
            setHeight: "260px",
            theme:"dark"
        });

    }
    else{
        $(".irish-racing").mCustomScrollbar("destroy");
        $(".about-text").mCustomScrollbar("destroy");

        $(".horse-racing").mCustomScrollbar({
            setHeight: "490px",
            theme:"dark"
        });
    }


    $('.overlay').hover(
        function() {
                $(this).stop().animate({
                    backgroundPositionX: '100%',
                    backgroundPositionY: '100%'
                },1500,function()
                {
                    $(this).css("opacity","0");
                });

        },
        function () {
            $(this).css("opacity","1");
                $(this).stop().animate({
                    backgroundPositionX: '0%',
                    backgroundPositionY: '0%'
                },1500);
        }
    );


});


